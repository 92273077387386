import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./i18n/locales/en/translations.json"),
    },
    ar: {
      translations: require("./i18n/locales/ar/translations.json"),
    },
    sp: {
      translations: require("./i18n/locales/sp/translations.json"),
    },
    hi: {
      translations: require("./i18n/locales/hi/translations.json"),
    },
    ka: {
      translations: require("./i18n/locales/ka/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ar", "sp", "hi", "ka"];

export default i18n;

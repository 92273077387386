import React from "react";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Layout from "../pages/Layout";
import { isEmpty } from "lodash";
import { Col } from "react-bootstrap";
const SidebarLayout = ({ heading, children }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Layout>
        <Col md={9}>
          {!isEmpty(heading) && (
            <div className="balance-label position-relative mb-2">
              {heading}
              <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
                <RxCross2
                  className="fs-3"
                  style={{ fontWeight: "800" }}
                  onClick={() => navigate("/")}
                />
              </button>
            </div>
          )}
          {children}
        </Col>
      </Layout>
    </div>
  );
};

export default SidebarLayout;

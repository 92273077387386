import React, { useEffect, useContext } from "react";
import UpcomingDark from "../../img/upcoming-dark.png";
import live from "../../img/live.png";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import BatBlue from "../../img/bat-blue.png";
import trophy from "../../img/trophy.png";
import telecase from "../../img/telecast.png";
import { useState } from "react";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import { isEmpty } from "lodash";
import ListSection from "./ListSection";
import AuthContext from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import VSWhite from "../../img/vs-white.png";
import helper from "../../Utils/helpers";
import { io } from "socket.io-client";
import NoEvent from "../../component/NoEvent";
import { useTranslation } from "react-i18next";
const ListPage = () => {
  const { user } = useContext(AuthContext);
  const { sportType } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [spark, setSpark] = useState(false);
  const [list, setList] = useState([]);
  const [allOdds, setAllOdds] = useState([]);
  const getOdds = async (id) => {
    let array = id
      ?.map((item) => {
        return item?.marketId;
      })
      .flat();
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${array?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setSpark(true);
          setAllOdds(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    let timmer = setTimeout(() => {
      setSpark(false);
    }, 500);
    return () => {
      clearTimeout(timmer);
    };
  }, [allOdds]);

  const getData = async () => {
    const { status, data } = await apiGet(apiPath.matchList, {
      gameType: isEmpty(sportType) ? "All" : sportType,
    });
    if (data?.success) {
      let obj = {};
      if (isEmpty(sportType)) {
        obj = {
          cricket:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "cricket" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          tennis:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "tennis" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          soccer:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "soccer" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          upcomingHome:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "cricket" &&
                  res?.status == "active" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          allInplay:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.status == "in_play" && !res?.eventName.includes(" SRL")
              )
            ) || [],
        };
      } else {
        obj = {
          allInplay:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.status == "in_play" && !res?.eventName.includes(" SRL")
              )
            ) || [],
          cricket:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "cricket" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          tennis:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "tennis" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          soccer:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "soccer" &&
                  res?.status == "in_play" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          cricketUpcoming:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "cricket" &&
                  res?.status == "active" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          tennisUpcoming:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "tennis" &&
                  res?.status == "active" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
          soccerUpcoming:
            sortBy(
              data?.results?.filter(
                (res) =>
                  res?.gameType == "soccer" &&
                  res?.status == "active" &&
                  !res?.eventName.includes(" SRL")
              )
            ) || [],
        };
      }
      getOdds(data?.results);
      setData(obj);
      setList(data?.results);
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      let interval = setInterval(() => {
        getOdds(list);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [list]);

  const sortBy = (array) => {
    return array?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  useEffect(() => {
    getData();
  }, [sportType]);

  let lengthObj = {
    1: 12,
    2: 6,
    3: 4,
  };

  // Socket
  const [cricketScore, setCricketScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  return (
    <>
      {data?.allInplay?.length >= 2 && (
        <div
          data-aos="zoom-in-up"
          data-aos-easing="ease"
          data-aos-delay="100"
          className="br-6 p-3 mb-1"
        >
          <Carousel>
            <Carousel.Item>
              <Row>
                {data?.allInplay?.slice(0, 2)?.map((item, index, obj) => {
                  let Odds =
                    allOdds?.length > 0
                      ? allOdds?.find((res) => {
                          return res?.mi == item?.marketId;
                        })
                      : {};
                  let odds_lay =
                    Odds?.rt?.length > 0
                      ? Odds?.rt?.filter((todd) => !todd?.ib)
                      : [];
                  let odds_back =
                    Odds?.rt?.length > 0
                      ? Odds?.rt?.filter((todd) => todd?.ib)
                      : [];
                  return (
                    <Col md={lengthObj[obj?.length]}>
                      <div className="matchBox br-6">
                        <h4>
                          {item?.seriesName}
                          <span>
                            Today{" "}
                            {helper?.msgDateFormatTime(item?.eventDateTime)}
                          </span>
                        </h4>
                        <Row>
                          <Col className="text-center" md={5}>
                            <p>{item?.eventName?.split(" v ")[0]}</p>
                          </Col>
                          <Col className="text-center align-middle" md={2}>
                            <img
                              src={VSWhite}
                              style={{
                                maxWidth: "20px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                          <Col className="text-center" md={5}>
                            <p>{item?.eventName?.split(" v ")[1]}</p>
                          </Col>
                        </Row>
                        {/* <h5>Match Result</h5> */}
                        {odds_back?.length > 0 && (
                          <Row className="mt-2">
                            <Col className="text-center pe-0" md={4}>
                              <div className="oddNumber d-flex">
                                <span className="w-25">{odds_back[1]?.rt}</span>
                                <span className="w-75 text-end">
                                  {odds_lay[1]?.rt}
                                </span>
                              </div>
                            </Col>
                            <Col
                              className="text-center  align-middle px-1"
                              md={4}
                            >
                              <div className="oddNumber d-flex">
                                <span className="w-25">{odds_back[0]?.rt}</span>
                                <span className="w-75 text-end">
                                  {odds_lay[0]?.rt}
                                </span>
                              </div>
                            </Col>
                            <Col className="text-center  ps-0" md={4}>
                              <div className="oddNumber d-flex">
                                <span className="w-25">{odds_back[2]?.rt}</span>
                                <span className="w-75 text-end">
                                  {odds_lay[2]?.rt}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
            {data?.allInplay?.length >= 4 && (
              <Carousel.Item>
                <Row>
                  {data?.allInplay?.slice(2, 4)?.map((item, index, obj) => {
                    let Odds =
                      allOdds?.length > 0
                        ? allOdds?.find((res) => {
                            return res?.mi == item?.marketId;
                          })
                        : {};
                    let odds_lay =
                      Odds?.rt?.length > 0
                        ? Odds?.rt?.filter((todd) => !todd?.ib)
                        : [];
                    let odds_back =
                      Odds?.rt?.length > 0
                        ? Odds?.rt?.filter((todd) => todd?.ib)
                        : [];
                    return (
                      <Col md={lengthObj[obj?.length]}>
                        <div className="matchBox br-6">
                          <h4>
                            {item?.seriesName}
                            <span>
                              Today{" "}
                              {helper?.msgDateFormatTime(item?.eventDateTime)}
                            </span>
                          </h4>
                          <Row>
                            <Col className="text-center" md={5}>
                              <p>{item?.eventName?.split(" v ")[0]}</p>
                            </Col>
                            <Col className="text-center align-middle" md={2}>
                              <img
                                src={VSWhite}
                                style={{
                                  maxWidth: "20px",
                                  marginTop: "10px",
                                }}
                              />
                            </Col>
                            <Col className="text-center" md={5}>
                              <p>{item?.eventName?.split(" v ")[1]}</p>
                            </Col>
                          </Row>
                          {/* <h5>Match Result</h5> */}
                          {odds_back?.length > 0 && (
                            <Row className="mt-2">
                              <Col className="text-center pe-0" md={4}>
                                <div className="oddNumber d-flex">
                                  <span className="w-25">
                                    {odds_back[1]?.rt}
                                  </span>
                                  <span className="w-75 text-end">
                                    {odds_lay[1]?.rt}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="text-center  align-middle px-1"
                                md={4}
                              >
                                <div className="oddNumber d-flex">
                                  <span className="w-25">
                                    {odds_back[0]?.rt}
                                  </span>
                                  <span className="w-75 text-end">
                                    {odds_lay[0]?.rt}
                                  </span>
                                </div>
                              </Col>
                              <Col className="text-center  ps-0" md={4}>
                                <div className="oddNumber d-flex">
                                  <span className="w-25">
                                    {odds_back[2]?.rt}
                                  </span>
                                  <span className="w-75 text-end">
                                    {odds_lay[2]?.rt}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
      )}
      {(data?.soccer?.length > 0 ||
        data?.cricket?.length > 0 ||
        data?.tennis?.length > 0) && (
        <div
          data-aos="zoom-in-down"
          data-aos-easing="ease"
          data-aos-delay="100"
          className=" br-6 mb-3"
        >
          {/* <h2 className="mb-3">
            <img src={live} />
            {t("InPlay")}
            <span className="float-end">
              <img src={UpcomingDark} /> {t("InPlay")}
            </span>
          </h2> */}
          {data?.cricket?.length > 0 && (
            <ListSection
              heading={t("Cricket")}
              data={data?.cricket}
              allOdds={allOdds}
              spark={spark}
              score={[...score, ...cricketScore]}
              type={t("Live")}
            />
          )}{" "}
          {data?.tennis?.length > 0 && (
            <ListSection
              heading={t("Tennis")}
              data={data?.tennis}
              allOdds={allOdds}
              spark={spark}
              score={[...score, ...cricketScore]}
              type={t("Live")}
            />
          )}{" "}
          {data?.soccer?.length > 0 && (
            <ListSection
              heading={t("Soccer")}
              data={data?.soccer}
              allOdds={allOdds}
              spark={spark}
              score={[...score, ...cricketScore]}
              type={t("Live")}
            />
          )}
        </div>
      )}
      {list?.length > 0 && (
        <div
          data-aos="zoom-in-down"
          data-aos-easing="ease"
          data-aos-delay="100"
          className=" br-6 mb-3"
        >
          {/* <h2 className="mb-3">
            {t("Upcoming")}
            <span className="float-end">
              <img src={UpcomingDark} /> {t("Upcoming")}
            </span>
          </h2> */}
          {isEmpty(sportType) && data?.upcomingHome?.length > 0 && (
            <ListSection
              heading={t("Cricket")}
              data={data?.upcomingHome}
              allOdds={allOdds}
              spark={spark}
              score={[...score, ...cricketScore]}
              type={t("Upcoming")}
            />
          )}{" "}
          {!isEmpty(sportType) && (
            <>
              {data?.cricketUpcoming?.length > 0 && (
                <ListSection
                  data={data?.cricketUpcoming}
                  allOdds={allOdds}
                  heading={t("Cricket")}
                  spark={spark}
                  score={[...score, ...cricketScore]}
                  type={t("Upcoming")}
                />
              )}
              {data?.tennisUpcoming?.length > 0 && (
                <ListSection
                  data={data?.tennisUpcoming}
                  allOdds={allOdds}
                  heading={t("Tennis")}
                  spark={spark}
                  score={[...score, ...cricketScore]}
                  type={t("Upcoming")}
                />
              )}
              {data?.soccerUpcoming?.length > 0 && (
                <ListSection
                  data={data?.soccerUpcoming}
                  allOdds={allOdds}
                  heading={t("Soccer")}
                  spark={spark}
                  score={[...score, ...cricketScore]}
                  type={t("Upcoming")}
                />
              )}
            </>
          )}
        </div>
      )}
      {isEmpty(list) && <NoEvent />}
    </>
  );
};

export default ListPage;

import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "../img/logo3.png";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import loginImg from "../img/login-icon.png";
import live from "../img/live.png";
import sportBet from "../img/sport-betting.png";
import Casino from "../img/casino.png";
import LuckyDrops from "../img/l-drops.png";
import LiveDrops from "../img/l-drops.png";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Promotion from "../img/promotion.png";
import coin from "../img/coin.png";
import userImg from "../img/user.png";
import { IoMdLogIn } from "react-icons/io";
import { HiLogout, HiOutlineRefresh } from "react-icons/hi";
import { FaFileExport, FaFileImport, FaHome } from "react-icons/fa";
import { BiSolidFileExport, BiSolidFileImport } from "react-icons/bi";
import { MdCasino, MdOutlineBlindsClosed, MdWorkHistory } from "react-icons/md";
import { GiFishingLure } from "react-icons/gi";
import Auth from "../img/auth.png";
import BetsMenu from "../img/bets.png";
import BettingPL from "../img/betting-pl.png";
import CPassword from "../img/c-password.png";
import CrossImg from "../img/cross-img.png";
import Fav from "../img/favorite.png";
import News from "../img/news.png";
import Rules from "../img/rules.png";
import StakeSetting from "../img/stake-setting.png";
import Transfer from "../img/transfer.png";
import { RiCustomerService2Line } from "react-icons/ri";
import { SiBetfair } from "react-icons/si";
import Aos from "aos";
import "aos/dist/aos.css";
import props from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { FaTable } from "react-icons/fa";
import { MdLiveTv } from "react-icons/md";
import { useTranslation } from "react-i18next";
import English from "../img/icons/united-kingdom.png";
import Arabic from "../img/icons/united-arab-emirates.png";
import Kannad from "../img/icons/kannad.png";
import Hindi from "../img/icons/square.png";
import Spanish from "../img/icons/flag.png";
import Select, { components } from "react-select";
import { MdAccountBalance } from "react-icons/md";
import { MdAssignmentTurnedIn } from "react-icons/md";
function Header() {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  let {
    user,
    showSidebar,
    handleShowSidebar,
    userCoins,
    amounutRefresh,
    refreshAmountLoader,
    message,
    logoutUser,
    changeLanguage,
    profileData,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const countries = [
    { value: "en", label: "English", icon: English },
    { value: "ka", label: "Kannad", icon: Kannad },
    { value: "ar", label: "Arabic", icon: Arabic },
    { value: "sp", label: "Spanish", icon: Spanish },
    { value: "hi", label: "Hindi", icon: Hindi },
  ];
  const Option = (props) => (
    <components.Option
      {...props}
      className="country-option"
      style={{ padding: "3px 10px", marginBottom: "10px" }}
    >
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={props.data.icon}
        alt="logo"
        className="country-logo"
      />
      {props.data.label}
    </components.Option>
  );
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={
          isEmpty(localStorage.getItem("language_dir"))
            ? English
            : countries?.find((res) => {
                return res?.value == localStorage.getItem("language_dir");
              })?.icon
        }
        alt="s-logo"
        className="selected-logo"
      />
      {children}
    </components.SingleValue>
  );

  return (
    <header data-aos="fade-down" className="sticky-top header">
      <Navbar
        expand="lg"
        className="justify-content-between"
        style={{ background: "#303030" }}
      >
        <Container fluid>
          <Navbar.Brand className="ms-2" href="#" onClick={() => navigate("/")}>
            <img src={logo} style={{ maxWidth: "180px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                className="d-flex align-items-center"
                onClick={() => navigate("/")}
                href="#"
              >
                <FaHome size={17} style={{ marginRight: "5px" }} />
                {t("Home")}
              </Nav.Link>{" "}
              <Nav.Link
                className="d-flex align-items-center"
                onClick={() => navigate("/casino/poker")}
                href="#"
              >
                {/* <img src={Casino} /> */}
                <MdCasino size={17} style={{ marginRight: "5px" }} />
                {t("Indian_Poker")}
              </Nav.Link>{" "}
              <Nav.Link
                onClick={() => navigate("/casino/Live")}
                href="#"
                className="d-flex align-items-center"
              >
                {/* <img src={Casino} /> */}
                <MdLiveTv size={17} style={{ marginRight: "5px" }} />
                {t("Live")}
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/casino/Table")}
                href="#"
                className="d-flex align-items-center"
              >
                {/* <img src={Casino} /> */}
                <FaTable size={17} style={{ marginRight: "5px" }} />
                {t("Table")}
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/casino/Fishing")} href="#">
                <GiFishingLure size={17} style={{ marginRight: "5px" }} />
                {t("Fishing")}
              </Nav.Link>
              {!isEmpty(user) && (
                <>
                  <Nav.Link
                    onClick={() => navigate("/deposit")}
                    href="#"
                    className="d-flex align-items-center"
                  >
                    {/* <img src={sportBet} /> */}
                    <FaFileImport size={17} style={{ marginRight: "5px" }} />
                    {t("Deposit")}
                  </Nav.Link>
                  <Nav.Link
                    className="d-flex align-items-center"
                    onClick={() => navigate("/withdraw")}
                    href="#"
                  >
                    {/* <img src={sportBet} /> */}
                    <FaFileExport size={17} style={{ marginRight: "5px" }} />
                    {t("Withdraw")}
                  </Nav.Link>{" "}
                </>
              )}
              {/* {!isEmpty(user) && (
                <Nav.Link onClick={() => navigate("/bets-history")} href="#">
                  <img src={sportBet} />
                  Betting History
                </Nav.Link>
              )} */}
              {/* {!isEmpty(user) && (
                <>
                  <Nav.Link onClick={() => navigate("/current-bets")} href="#">
                    <img src={LuckyDrops} />
                    Current Bets
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => navigate("/profit-and-loss")}
                    href="#"
                  >
                    <img src={LiveDrops} />
                    Profit & Loss
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => navigate("/customer-support")}
                    href="#"
                  >
                    <img src={Promotion} />
                    Customer Support
                  </Nav.Link>
                </>
              )} */}
            </Nav>
            {!isEmpty(user) ? (
              <div className="d-flex me-2">
                {/* <span className="amounts me-2">
                  <img src={coin} /> ${user?.user?.totalCoins}
                </span> */}
                {!isEmpty(user) && (
                  <div className="d-flex align-items-center after-login">
                    {refreshAmountLoader ? (
                      <div class="loader-outer">
                        <span
                          class="loader-inner-icon"
                          style={{ animationDelay: "0s" }}
                        ></span>
                        <span
                          class="loader-inner-icon"
                          style={{ animationDelay: ".1s" }}
                        ></span>
                        <span
                          class="loader-inner-icon"
                          style={{ animationDelay: ".2s" }}
                        ></span>
                        <span
                          class="loader-inner-icon"
                          style={{ animationDelay: ".3s" }}
                        ></span>
                        <span
                          class="loader-inner-icon"
                          style={{ animationDelay: ".4s" }}
                        ></span>
                      </div>
                    ) : (
                      <span className="amounts me-1 d-flex align-items-center justify-content-start">
                        <img src={coin} />
                        <div style={{ marginLeft: "5px" }}>
                          <div>
                            {t("INR")}{" "}
                            <span style={{ marginLeft: "4px" }}>
                              {userCoins?.balance?.toFixed()}
                            </span>
                          </div>
                          <div>
                            {t("Exp")}
                            <span style={{ color: "red", marginLeft: "4px" }}>
                              {userCoins?.exp?.toFixed()}
                            </span>
                          </div>
                        </div>
                      </span>
                    )}
                    <div onClick={amounutRefresh}>
                      <HiOutlineRefresh
                        size={20}
                        color="white"
                        className={refreshAmountLoader ? "refresh-icon" : ""}
                      />
                    </div>
                  </div>
                )}
                <Dropdown className="uDetails d-inline-block">
                  <Dropdown.Toggle id="dropdown-basic" align="end">
                    <span className="u-name text-start">
                      {t("Welcome")},
                      <br />
                      {user?.user?.username}
                    </span>
                    <span className="u-img">
                      <img src={userImg} />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div
                      style={{ marginLeft: ".5rem" }}
                      className="whiteBox p-2 br-6"
                    >
                      <img src={userImg} className="uimage" />
                      <h2 className="uname"> {user?.user?.username}</h2>
                      <br />
                      <span style={{ fontSize: "13px", color: "white" }}>
                        {profileData?.phone} <RiVerifiedBadgeFill/>
                      </span>
                    </div>
                    <div className="values  mx-2 my-2">
                      <Row className="mx-0">
                        <Col className="px-2 py-2">
                          {user?.user?.totalCoins}{" "}
                          <span>{t("Available_Balance")}</span>
                        </Col>
                        <Col className="px-2 py-2">
                          <span
                            style={{
                              color: "red",
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            {user?.user?.exposure}
                          </span>{" "}
                          <span>{t("Exposure")}</span>
                        </Col>
                      </Row>
                      {/* <Row className="mx-0">
                      <Col className="px-2 py-2 b-r">
                        500 <span>Winnings</span>
                      </Col>
                      <Col className="px-2 py-2 c-red">
                        -75 <span>Net Exposure</span>
                      </Col>
                    </Row> */}
                    </div>

                    <div
                      style={{ marginLeft: ".5rem" }}
                      className="whiteBox p-2 br-6"
                    >
                      <ul className="u-menus">
                        <li>
                          <Link to="/current-bets">
                            <SiBetfair size={20} /> {t("CurrentBet")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/profit-and-loss">
                            <MdOutlineBlindsClosed size={20} />{" "}
                            {t("ProfitLoss")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/bets-history">
                            <MdWorkHistory size={20} /> {t("BetHistory")}
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/deposit">
                            <img src={LiveDrops} /> Deposit
                          </Link>
                        </li>{" "} */}
                        <li>
                          <Link to="/deposit-history">
                            <BiSolidFileImport size={20} />{" "}
                            {t("DepositHistory")}
                          </Link>
                        </li>{" "}
                        {/* <li>
                          <Link to="/withdraw">
                            <img src={LiveDrops} /> Withdraw
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/withdraw-history">
                            <BiSolidFileExport size={20} />{" "}
                            {t("WithdrawHistory")}
                          </Link>
                        </li>
                        {/* <li>
                  <Link to="/balance-overview">
                    <img src={LiveDrops} /> Balance Overview
                  </Link>
                </li>{" "} */}
                        <li>
                          <Link to="/account-statement">
                            <MdAccountBalance
                              size={20}
                              style={{ marginRight: "5px" }}
                            />{" "}
                            {t("AccountStatement")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/customer-support">
                            <RiCustomerService2Line size={20} />{" "}
                            {t("CustomerSupport")}
                          </Link>
                        </li> <li>
                          <Link to="/turnover">
                            <MdAssignmentTurnedIn size={20} style={{ marginRight: "5px" }}/>
                            Turnover
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/customer-support">
                            <RiCustomerService2Line size={20} />{" "}
                            {t("CustomerSupport")}
                          </Link>
                        </li>{" "} */}
                        <li>
                          <Select
                            value={
                              isEmpty(localStorage.getItem("language_dir"))
                                ? English
                                : countries?.find((res) => {
                                    return (
                                      res?.value ==
                                      localStorage.getItem("language_dir")
                                    );
                                  })
                            }
                            options={countries}
                            onChange={(e) => changeLanguage(e.value)}
                            styles={{
                              singleValue: (base) => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                            components={{ Option, SingleValue }}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="d-grid gap-2">
                      <Button
                        onClick={() => logoutUser()}
                        variant="primary"
                        className="btn-red mx-2"
                        size="sm"
                      >
                        {t("LogOut")}
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="social-header">
                <Link to="/register" className="login">
                  <IoMdLogIn
                    size={16}
                    style={{ marginRight: "4px" }}
                    color="black"
                  />
                  {t("Singup")}
                </Link>
                <Link to="/login" className="login">
                  <IoMdLogIn
                    size={16}
                    style={{ marginRight: "4px" }}
                    color="black"
                  />
                  {t("Login")}
                </Link>
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="subNav">
        <ul>
          <li>
            <Link to="/" className={location?.pathname == "/" && "active"}>
              {t("Home")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname?.split("/")[1] == "cricket" && "active"
              }
              to="/cricket"
            >
              {t("Cricket")}
            </Link>
          </li>
          <li>
            <Link
              to="/tennis"
              className={
                location?.pathname?.split("/")[1] == "tennis" && "active"
              }
            >
              {t("Tennis")}
            </Link>
          </li>
          <li>
            <Link
              to="/soccer"
              className={
                location?.pathname?.split("/")[1] == "soccer" && "active"
              }
            >
              {t("Soccer")}
            </Link>
          </li>
          <li>
            <Link
              className={
                location?.pathname?.split("/")[1] == "casino" && "active"
              }
              to="/casino/poker"
            >
              {t("Casino")}
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;

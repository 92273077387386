import React from "react";
import { useState } from "react";

const SubCasino = ({ header, subCasino, setSubCasino }) => {
  const live = ["SEXY", "EVO", "PT", "PP", "HOTROAD"];
  const table = ["KINGMAKER", "NETENT", "JILI"];
  const slot = [
    "PP",
    "KINGMAKER",
    "DRAGOONSOFT",
    "NETENT",
    "SPADE",
    "PT",
    "JDB",
    "FC",
    "JILI",
  ];
  const fishing = ["SPADE", "JDB", "JILI"];
  const egame = ["SPRIBE", "PP", "JDB"];
  const awc = ["BG", "BTG", "E1SPORT", "NLC", "RT", "SV388", "YesBingo", "YL"];
  const obj = {
    Live: live,
    Slot: slot,
    Table: table,
    Fishing: fishing,
    Egame: egame,
    AWC: awc,
  };
  return (
    <>
      <div className="d-flex align-items-center flex-wrap subcasino">
        {obj[header]?.map((res) => {
          return (
            <span
              onClick={() => setSubCasino(res)}
              className={subCasino === res && "active"}
            >
              {res}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default SubCasino;

import { render, screen } from '@testing-library/react';
import React, { useEffect } from 'react';

import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import loginImg from '../img/login-img.png';
import logo from '../img/logo.png';

import Aos from 'aos';
import 'aos/dist/aos.css';

function UpdatePassword() {
    useEffect(()=>{
        Aos.init({duration:500})
      },[]
      )
    return (
        <>
         <div className='login'>
             <Container className='h-100 align-middle'>
                <Row className='h-100 align-items-center align-middle justify-content-center'>
                    <Col md={10}>
                        <Row>
                            <Col md={7} className='text-start'>
                                <img data-aos='zoom-in-down' src={loginImg} style={{width:'90%'}}/>
                            </Col>
                            <Col md={5} >
                                <div data-aos='flip-up' className='text-center mt-5 mb-3'><img src={logo} style={{maxWidth:'200px'}}/></div>
                                <span data-aos='fade-up' className='success mb-3'>
                                   Password updated successfully. 
                                </span>
                                <div data-aos='zoom-in' className='loginForm'>
                                    <Form.Label >New Password</Form.Label>
                                    <Form.Control className='mb-3' type="text" id="username" />
                                    <Form.Label >Confirm New Passord</Form.Label>
                                    <Form.Control className='mb-3' type="text" id="username" />
                                    <Button href='/Home' variant="primary">Updated</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
         </div>
        </>
    );
}

export default UpdatePassword;
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { isEmpty, startCase } from "lodash";
import { useTranslation } from "react-i18next";
const OpenBets = ({ data }) => {
  const {t} = useTranslation()
  return (
    <div className="leagueBox mb-3">
      <div className="title mb-2">
        <Row>
          <Col xs={7}>{t("OpenBets")}</Col>

          <Col xs={5} className="text-center">
            <Row>
              <Col className="text-center text-white px-0">{t("Odds")}</Col>
              <Col className="text-center text-white px-0">{t("Stake")}</Col>
            </Row>
          </Col>
        </Row>
      </div>

      {data?.map((item, index) => {
        return (
          <div  style={{borderBottom:"1px solid #ddd",background:"white"}} className="laegueRow">
            <Row>
              <Col xs={7} className="d-flex align-items-center text-black">
                <Button
                  style={{
                    background:
                      item?.type == "No" || item?.betType == "lay"
                        ? "#FFE6E4"
                        : "#CFEBFF",
                    padding: "2px 5px",
                    color: "black",
                    border: "none",
                    marginRight: "5px",
                    fontSize:"12px"
                  }}
                >
                  {" "}
                  {item?.type || startCase(item?.betType)}
                </Button>{" "}
                {item?.runnerName || item?.teamName}
              </Col>
              <Col xs={5} className="text-center text-black px-0">
                <Row>
                  <Col className="text-center text-black">
                    {" "}
                    {!isEmpty(item?.fancyName)
                      ? `${item?.betRun} / ${item?.bhav}`
                      : item?.bhav}
                  </Col>
                  <Col className="text-center text-black">{item?.amount}</Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default OpenBets;
